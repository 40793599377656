<template>
  <div class="program-container">
    <!-- Hero -->
    <hero :content="hero" />

    <!-- Section Container -->
    <div class="container my-4">
      <!-- Recent Blog -->
      <section>
        <h2 class="title">
          Recent blog posts
        </h2>

        <!-- Highlight Articles -->
        <b-row class="my-2">
          <!-- Big Article -->
          <b-col lg="6">
            <article-card class="h-100" variant="big" />
          </b-col>

          <!-- Right Article -->
          <b-col lg="6">
            <article-card variant="right" />
            <article-card variant="right" />
          </b-col>
        </b-row>
      </section>

      <!-- Recent Blog -->
      <section>
        <h2 class="title">
          All blog posts
        </h2>

        <!-- Highlight Articles -->
        <b-row cols="3" class="my-2">
          <!-- Big Article -->
          <b-col v-for="i in 9" :key="i">
            <article-card />
          </b-col>
        </b-row>
      </section>

      <!-- Pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="d-flex justify-content-center"
      >
        <template #prev-text>
          <div class="float-left">
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </div>
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>

    <!-- Explore -->
    <explore />

    <!-- Footer -->
    <custom-footer />
  </div>
</template>

<script>
import { BButton, BRow, BCol, BBadge, BLink, BPagination } from 'bootstrap-vue'

// Custom Components
import Explore from '@/views/tekov/landingpage/components/Explore.vue'
import CustomFooter from '@/views/tekov/landingpage/components/Footer.vue'
import Hero from '@/views/tekov/components/Hero.vue'
import ArticleCard from './components/ArticleCard.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BBadge,
    BLink,
    BPagination,

    ArticleCard,
    Hero,

    Explore,
    CustomFooter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 1,
      rows: 100,

      hero: {
        title: 'Temukan Artikel Insightful agar Kamu Semakin Tahu',
        desc:
          'Gali potensi yang selama ini kamu yakini bersama mentor yang ahli dibidangnya,kamu bakal dipandu sampai bener bener paham.',
      },
    }
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss">
.program-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px !important;
}

.list-padding {
  padding: 0.4rem 0;
}

.offset-mt {
  margin-top: -18rem;
}

.clean-paragraph {
  text-justify: inter-word;
  line-height: 26px;
}

.font-regular {
  font-size: 1.2rem;
}
</style>
