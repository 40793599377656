<template>
  <div>
    <!-- Variant : Default -->
    <div v-if="variant === 'default'" class="mb-2">
      <img class="w-100 mb-1" src="@/assets/images/slider/05.jpg" alt="" />

      <!-- Date -->
      <span class="text-primary font-weight-bolder">
        {{ article.author }} • {{ dateFormat(article.date) }}
      </span>
      <b-link
        class="d-flex justify-content-between pt-1"
        :to="{ name: 'article-detail', params: { id: article.id } }"
      >
        <h4>
          {{ article.title }}
        </h4>
        <i class="ti ti-arrow-up-right font-regular" />
      </b-link>
      <p class="m-0">
        {{ article.description }}
      </p>
      <div class="py-1">
        <b-badge
          v-for="(tag, i) in article.tags"
          :key="tag"
          :variant="tag.color"
        >
          {{ tag.name }}
        </b-badge>
      </div>
    </div>

    <!-- Variant : Big -->
    <div v-if="variant === 'big'">
      <img
        class="w-100 mb-1 h-fit"
        src="@/assets/images/slider/06.jpg"
        alt=""
      />

      <div class="d-flex flex-column justify-content-center">
        <!-- Date -->
        <span class="text-primary font-weight-bolder">
          {{ article.author }} • {{ dateFormat(article.date) }}
        </span>
        <b-link
          class="d-flex justify-content-between pt-1"
          :to="{ name: 'article-detail', params: { id: article.id } }"
        >
          <h4>
            {{ article.title }}
          </h4>
          <i class="ti ti-arrow-up-right font-regular" />
        </b-link>
        <p class="m-0">
          {{ article.description }}
        </p>
        <div class="py-1">
          <b-badge
            v-for="(tag, i) in article.tags"
            :key="tag"
            :variant="tag.color"
          >
            {{ tag.name }}
          </b-badge>
        </div>
      </div>
    </div>

    <!-- Variant : Small -->
    <div class="d-flex mb-2" v-if="variant === 'right'">
      <img class="w-100 mr-2" src="@/assets/images/slider/04.jpg" alt="" />

      <div class="">
        <!-- Date -->
        <span class="text-primary font-weight-bolder">
          {{ article.author }} • {{ dateFormat(article.date) }}
        </span>
        <b-link
          class="d-flex justify-content-between pt-1"
          :to="{ name: 'article-detail', params: { id: article.id } }"
        >
          <h4>
            {{ article.title }}
          </h4>
          <i class="ti ti-arrow-up-right font-regular" />
        </b-link>
        <p class="m-0">
          {{ article.description }}
        </p>
        <div class="py-1">
          <b-badge
            v-for="(tag, i) in article.tags"
            :key="tag"
            :variant="tag.color"
          >
            {{ tag.name }}
          </b-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge, BLink } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BLink,
  },
  props: {
    article: {
      type: Object,
      default: () => {
        return {
          id: 1,
          title: 'UX review presentations',
          description:
            'How do you create compelling presentations that wow your colleagues and impress your managers?',
          date: '20 Jan 2022',
          author: 'Olivia Rhye',
          image: '@/assets/images/slider/06.jpg',
          tags: [
            {
              name: 'Design',
              color: 'light-primary',
            },
          ],
        }
      },
    },
    variant: {
      type: String,
      default: 'default', // big, default, right
    },
  },
  methods: {
    dateFormat(date) {
      return new Date(date).toLocaleDateString('id-ID', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.h-fit {
  object-fit: cover;
  height: 300px;
}

.h-full {
  height: 100%;
}
</style>
